import React from 'react';
import Layout from '../components/Layout';

const uno = () => {
    return (
        <Layout>
            <div id="main">
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title" style={{fontSize: 'x-large'}}>What is Blockchain?</h1>

                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{fontSize: 'large', color: '#000'}}>
                                In simple words, the blockchain is a unique distributed record that stores data and
                                verifies its integrity. By using a different set of cryptography based technologies,
                                Blockchain assures that transaction continued into the blockchain database which is
                                stable.
                            </p>
                            <p style={{fontSize: 'large', color: '#000'}}>
                                Blockchain technology manages the every currency transactions. But Blockchain is not
                                limited to just currency but enlarges to any domain where anything of value is
                                transacted, be it contracts, personal information, health records, business data and
                                much more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default uno;
